<template>
  <div class="Agreement">
    <div class="top-title">
      <div class="top-text">等级设置</div>
      
    </div>

      <div class="agreement-ul">
       
          <ul>
             <li><p>等级名称</p><p>升级条件</p><p>操作</p></li>
            <li>
              <p>超级会员</p>
              <p>分享拉取粉丝，直属粉丝数达到
                <input v-if="flag" type="text" style="border:1px solid #999; width:30px" 
                v-model="listpar.aaa" :placeholder="listpar.aaa"> 人，填写完合同，经理点击升级按钮后，即可升级</p>
              <p><el-button type="info" @click="bianji()" >编辑</el-button></p>
            </li>
          </ul>
      </div>

  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 上传图片的地址
      upLoadUrl:config.ImgUrl,
        flag:false,
       listpar:{},
    };
  },
  created() {
      this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'CommissionRuleEntity',
        handleMode:'fetchall',
         })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.listpar.aaa = res.Result.super.threshold
           this.flag =true
      },

      //弹窗
      bianji(){
          this.$confirm('是否确认修改', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.bianjis()
          }).catch(action => {
            console.log("取消");
          })
      },
  async  bianjis(){
      let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'CommissionRuleEntity',
        handleMode:'updateSuperThreshold',
        newThreshold:this.listpar.aaa
         })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.$alert('编辑成功', '消息提示', {confirmButtonText: '确定'});
    },



  },
  computed: {},
};
</script>


<style  scoped>

.agreement-ul{
  padding-top: 100px;
  width: 100%;
  margin: 0 auto;
}
.agreement-ul ul{
  width: 100%;
}
.agreement-ul ul li:nth-of-type(1) p{
  font-size: 18px;
  color: #000;
}
.agreement-ul ul li:nth-of-type(1){
  padding-bottom: 40px;
}
.agreement-ul ul li{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(153, 153, 153, 0.466);
  padding: 30px 0 30px ;
}
.agreement-ul ul li p{
width: 30%;
text-align: center;
/* display: flex;
justify-content: left */
}
@import "../../assets/css/liststyle.css";
</style>